import React, { useEffect } from "react"
import jobsData from "./jobsData"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableRow from "@mui/material/TableRow"
import Paper from "@mui/material/Paper"
import { useNavigate } from "react-router-dom"
import "styles/joinUs.scss"
import { PageTemplate } from "../PageTemplate"

function JoinUs() {
  const navigate = useNavigate()

  useEffect(() => {
    document.title = "Join Us"
  }, [])

  const storyStyle = {
    width: "37%",
    minWidth: 250,
    paddingTop: ".3em",
    margin: "auto",
    fontSize: "1.4rem",
    fontFamily: "rubikRegular",
  }

  const StyledTableRow = () => {
    return <TableRow sx={{
        "&:nth-of-type(odd)": {
            backgroundColor: "theme.palette.action.hover",
        },
        // hide last border
        "&:last-child td, &:last-child th": {
            border: 0,
        },
      }} />
  }

  function hanleJobClick(index) {
    navigate(`/join-us/${index}`)
  }

  return (
  <PageTemplate>
    <center>
      <center
        className="historyHeader"
        style={{ position: "relative", fontSize: "3.2rem" }}
      >
        Join Us
        <div style={storyStyle}>
          Your work can make a world of difference.
          <br />
          <i>We’re a widely diverse team using AI to serve humankind.</i>
        </div>
        <div className="bg-molecule" />
        <div className="bg-rounded-polygon" />
        <div className="bg-polygon-outline" />
      </center>

      <div style={{ maxWidth: "60%" }}>
        <div
          style={{ marginBottom: "5vh", fontWeight: "500", fontSize: "2rem" }}
        >
          The Current Career Openings
        </div>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableBody>
              {jobsData.map((job, index) => (
                <StyledTableRow
                  key={job.jobTitle}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    <div>
                      <h5>
                        <b>{job.jobTitle}</b>
                      </h5>
                      <h6>{job.branch}</h6>
                    </div>
                  </TableCell>
                  <TableCell align="right">
                    <button
                      className={"button"}
                      onClick={() => hanleJobClick(index)}
                    >
                      FIND OUT MORE
                    </button>
                  </TableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {jobsData.length === 0 ? (
          <div>
            <div
              style={{
                fontSize: "1.6rem",
                marginBottom: "25vh",
                marginTop: "5vh",
              }}
            >
              Unfortunately there are no job openings at the moment. Please
              check back later or feel free to contact us at <b>info@ro5.ai</b>
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
    </center>
  </PageTemplate>
  );
}

export default JoinUs
